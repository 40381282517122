import { Button, Container, Heading, HStack, Stack, useBreakpointValue, VStack } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { ReactNode } from 'react'

type Props = {
  title: string
  children: ReactNode
  link: string
  showButton: boolean
}

export function StartPageCard({ title, children, link, showButton }: Props) {
  const { t } = useTranslation('start')
  const isMobile = useBreakpointValue({ base: true, sm: false })

  return (
    <VStack
      px={{ base: 4, md: 6 }}
      py={{ base: 5, md: 7 }}
      flex={1}
      flexBasis="min-content"
      alignContent="start"
      h="min-content"
      bgColor="primary.white"
      borderRadius="2px"
    >
      <Container display="flex" flexDir="column" w="full" p={0}>
        <HStack mb={8} alignItems="start" justifyContent="space-between" width="full">
          <Heading as="h1">{title}</Heading>
          {showButton && !isMobile && (
            <Button as={NextLink} w="150px" variant="secondary" href={link}>
              {t('view-more')}
            </Button>
          )}
        </HStack>
        <Stack>{children}</Stack>
        {showButton && isMobile && (
          <Button as={NextLink} mt={10} w="full" variant="secondary" href={link}>
            {t('view-more')}
          </Button>
        )}
      </Container>
    </VStack>
  )
}
