import { Button, Flex, FlexProps, HStack, Heading, Skeleton } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { useEquipment } from 'bom/hooks/use-equipment'
import { ErrorAlert } from 'components/elements/ErrorAlert/ErrorAlert'
import { EquipmentCard } from 'components/modules/Equipment/EquipmentCard'
import { RoutePaths } from 'config/routes'
import { arrayHasValue } from 'utils/arrayHasValue'

export function StartPageEquipmentCardList(props: FlexProps) {
  const { t } = useTranslation('start')
  const { data: equipments, isIdle, isFetching, isLoading } = useEquipment()
  return (
    <Flex bg="white" flexDirection="column" w="full" px={4} py={6} {...props}>
      <Flex w="full" justifyContent="space-between" mb={10}>
        <Heading as="h1" size="xl" mb={2} color="primary.black">
          {t('find-spare-parts-for-your-equipment')}
        </Heading>

        <Button
          as={NextLink}
          minW="fit-content"
          variant="secondary"
          display={{ base: 'none', md: 'flex' }}
          href={RoutePaths.myEquipment}
        >
          {t('see-my-equipment')}
        </Button>
      </Flex>

      <HStack display="flex" w="full" overflow="auto">
        {isLoading || isIdle || isFetching ? (
          <HStack w="full">
            <Skeleton w="full" h={32} />
            <Skeleton w="full" h={32} />
            <Skeleton w="full" h={32} />
          </HStack>
        ) : arrayHasValue(equipments) ? (
          equipments.map((equipment) => <EquipmentCard key={equipment.id} equipment={equipment} isStartPage />)
        ) : (
          <ErrorAlert>{t('common:couldnt-find-any-items')}</ErrorAlert>
        )}
      </HStack>
    </Flex>
  )
}
