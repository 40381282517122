import { Box, Button, Flex, Heading, Link, Skeleton, Stack, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import { NextFallbackImage } from 'components/elements/NextFallbackImage/NextFallbackImage'

type Props = {
  equipment: BOM.Equipment
  isStartPage?: boolean
  isLoading?: boolean
}

export function EquipmentCard({ equipment, isStartPage, isLoading }: Props) {
  const { t, lang } = useTranslation('common')
  const imgSrc = `/images/equipment/types/${equipment?.equipmentType.replace('/', '')}.jpg`
  const equipmentName = getLocalizedString(equipment?.name, lang) || equipment?.name?.en

  const searchUrl = `/search?` + new URLSearchParams({ equipmentId: equipment?.id }).toString()

  return (
    <>
      {isLoading ? (
        <Skeleton h="240px" w="full" />
      ) : (
        <>
          {isStartPage ? (
            <Link
              href={searchUrl}
              as={NextLink}
              color="primary.black"
              borderBottom="1px solid"
              borderColor="primary.grey.500"
              mb={2}
            >
              <Stack w="full" verticalAlign="center" direction="row" align="center" spacing={6}>
                <NextFallbackImage src={imgSrc} width={112} height={112} alt="Equipment" />
                <Flex direction="column" align="start" w="314px">
                  <Heading as="h1" size="md">
                    {equipmentName}
                  </Heading>
                  <Text size="sm">{equipment?.serialNumber}</Text>
                </Flex>
              </Stack>
            </Link>
          ) : (
            <Box p={4} backgroundColor="primary.white" width="full" borderRadius="2px">
              <Stack
                w="full"
                direction={{ base: 'column', md: 'row' }}
                align={{ base: 'center', md: 'start' }}
                spacing={{ md: 20 }}
              >
                <NextFallbackImage src={imgSrc} width={240} height={240} alt="Equipment" />
                <Flex direction="column" align={{ base: 'center', md: 'start' }} pt={4}>
                  <Heading as="h2" size="lg" lineHeight={8} mb={2}>
                    {equipmentName}
                  </Heading>
                  <Text fontSize="xs" lineHeight={3} fontWeight="bold" mb={1}>
                    {t('serial-no')}
                  </Text>
                  <Text size="md" lineHeight={6}>
                    {equipment?.serialNumber}
                  </Text>
                  <Button href={searchUrl} as={NextLink} width="212px" variant="secondary" size="md" mt={6}>
                    {t('find-spare-parts')}
                  </Button>
                </Flex>
              </Stack>
            </Box>
          )}
        </>
      )}
    </>
  )
}
