import { useState } from 'react'

import Image from 'components/elements/Image'
import { ChakraNextImageProps, FALLBACK_IMAGE_SRC } from 'components/elements/Image/Image.config'

type Props = ChakraNextImageProps & { fallbackSrc?: string }

export function NextFallbackImage({ src, fallbackSrc = FALLBACK_IMAGE_SRC, width, height, alt, ...props }: Props) {
  const [imageSrc, setImageSrc] = useState(src)

  const handleError = () => setImageSrc(fallbackSrc)

  return (
    <Image
      height={height}
      width={width}
      src={imageSrc}
      style={{ objectFit: 'cover' }}
      onError={handleError}
      alt={alt}
      {...props}
    />
  )
}
