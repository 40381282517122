import { Box, Container, Flex, Heading } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

import Image from 'components/elements/Image'
import { StartPageEquipmentCardList } from 'components/modules/StartPage/StartPageEquipmentCardList'

const HERO_IMAGE_URL = '/images/start-page-image.jpg'

const HERO_HEIGHT = { base: 264, md: 694 }

const styles = {
  header: {
    color: 'white',
    position: 'relative',
    display: 'grid',
    maxW: 'full',
  },
  imageWrapper: {
    w: 'full',
    gridArea: '1 / -1',
    marginInline: 'auto',
    minH: '100%',
    height: HERO_HEIGHT,
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    maxW: 'desktop',
    gridArea: '1 / -1',
    pt: 10,
    pb: 10,
    px: 0,
  },
  headingWrapper: {
    flexDirection: 'column',
    w: 'full',
    maxW: 'desktop',
    pl: 10,
    alignSelf: 'center',
    pr: { base: 10, md: 0 },
  },
  heading: {
    fontSize: { base: '2rem', md: '56px' },
    color: 'primary.white',
    mb: { base: 2, md: 12 },
  },
} as const

export function StartPageHeader() {
  const { t } = useTranslation()

  return (
    <Box as="header" {...styles.header}>
      <Box {...styles.imageWrapper}>
        <Image
          src={HERO_IMAGE_URL}
          height={HERO_HEIGHT}
          maxW="100%"
          alt=""
          role="presentation"
          priority={true}
          loading="eager"
          width={{
            // Shifted breakpoint values - @see breakpoints in theme.ts
            base: 480,
            sm: 720,
            md: 960,
            lg: 1200,
            xl: 1440,
            '2xl': 1920,
          }}
          minH="100%"
          minWidth="100%"
          // minWidth has higher priority than maxWidth,
          // but that will fix overflow when image couldn't be loaded
          maxWidth="0"
          userSelect="none"
          objectFit="cover"
          objectPosition="center"
        />
      </Box>

      <Container {...styles.contentWrapper}>
        <Flex w="full" justifyContent="center">
          <Flex {...styles.headingWrapper}>
            <Heading as="h1" {...styles.heading}>
              {t('sites.store')}
            </Heading>
            <StartPageEquipmentCardList
              display={{ base: 'none', md: 'flex' }}
              // padding on the sides and scrollbar width
              maxW="calc(100vw - 40px - 40px - 12px) "
            />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}
