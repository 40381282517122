import { useBreakpointValue } from '@chakra-ui/react'
import isEqual from 'lodash/isEqual'
import { CSSProperties, FunctionComponent, memo, useMemo } from 'react'

import {
  ChakraNextImageProps,
  getResponsive,
  getBackgroundColor,
  ChakraNextImage,
  isResponsive,
  getSizes,
  FALLBACK_IMAGE_SRC,
} from 'components/elements/Image/Image.config'

/**
 * Chakra & Next.js image component
 *
 * @example
 * ```ts
 * <Image
 *   src={mediaAsset.url}
 *   alt={mediaAsset.title}
 *   width="668"
 *   height={510}
 * />
 * ```
 */
const Image: FunctionComponent<ChakraNextImageProps> = ({
  width,
  height,
  style = {},
  objectFit,
  src,
  alt,
  ...props
}) => {
  const responsiveWidth = useBreakpointValue(getResponsive(width))
  const responsiveHeight = useBreakpointValue(getResponsive(height))

  const imageWidth = isResponsive(width) ? responsiveWidth ?? width : width
  const imageHeight = isResponsive(height) ? responsiveHeight ?? height : height
  const imageStyle = useMemo<CSSProperties>(
    () =>
      ({ width: +imageWidth, height: +imageHeight, aspectRatio: `${+imageWidth}/${+imageHeight}`, ...style }) as const,
    [imageHeight, imageWidth, style],
  )

  const imageSrc = src || FALLBACK_IMAGE_SRC
  const imageAlt = alt ?? ''

  return (
    <ChakraNextImage
      {...props}
      backgroundColor={getBackgroundColor(style, objectFit, props)}
      fetchpriority={props.priority ? 'high' : 'auto'}
      loading={props.loading || (props.priority ? 'eager' : 'lazy')}
      priority={props.priority ?? props.loading === 'eager'}
      sizes={getSizes(width)}
      src={imageSrc}
      key={imageSrc} // Force remount when src changes
      alt={imageAlt}
      placeholder={props.blurDataURL ? 'blur' : undefined}
      objectFit={objectFit}
      style={imageStyle}
      width={imageWidth}
      height={imageHeight}
    />
  )
}

export default memo(Image, isEqual)
